<template>
    <main class="about">
        <div class="about-main__body">
            <div class="about-main__content">
                <h1 class="about-main__title">Cupohosting: La web líder de cupones en hosting y dominios</h1>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.cupohosting.bannerAboutUs1"
                    alt="Cupohosting banner"
                />

                <p class="about-main__text">
                    Bienvenidos a <b>Cupohosting</b>, el sitio número 1 en España para encontrar cupones y
                    ofertas exclusivos en <b>hosting</b>, <b>dominios</b>, <b>correo electrónico</b> y
                    servicios digitales relacionados. Reunimos los mejores descuentos de marcas reconocidas
                    como <b>Hostinger</b>, <b>Raiola Networks</b>, <b>Webempresa</b>, <b>IONOS</b>,
                    <b>Sered Hosting</b>, entre muchas otras. Nuestra misión es simplificar tu búsqueda de
                    ahorro, asegurándote siempre la mejor relación calidad-precio para tus proyectos
                    digitales.
                </p>

                <h2 class="about-main__subtitle">Nuestra historia</h2>

                <p class="about-main__text">
                    Desde 2017, comenzamos como un sitio enfocado en compartir cupones y descuentos en
                    diversas categorías. Sin embargo, en 2024, nos especializamos en el sector tecnológico,
                    creando <b>Cupohosting</b>, una plataforma dedicada exclusivamente a profesionales,
                    emprendedores y empresas que necesitan soluciones de hosting, dominios y servicios
                    relacionados.
                </p>

                <p class="about-main__text">
                    Hoy, <b>Cupohosting</b> es el destino de referencia para quienes desean ahorrar en
                    productos y servicios digitales de calidad, manteniendo un enfoque práctico y sencillo. El
                    sitio se reactiva, después de varios años pausado pero con muchas novedades y una mejora
                    en los contenidos y en el sitio.
                </p>

                <h2 class="about-main__subtitle">Nuestro propósito</h2>

                <p class="about-main__text">
                    En <b>Cupohosting</b>, buscamos facilitar el acceso a los mejores servicios digitales del
                    mercado al precio más competitivo. Creemos en la importancia del ahorro como un recurso
                    estratégico para impulsar proyectos digitales y negocios, ayudándote a obtener las
                    herramientas necesarias sin comprometer tu presupuesto.
                </p>

                <h2 class="about-main__subtitle">¿Cómo funciona Cupohosting?</h2>

                <p class="about-main__text">Explorar <b>Cupohosting</b> es fácil y rápido:</p>

                <ul class="about-main__list">
                    <li class="about-main__list-item">
                        Encuentra cupones y ofertas vigentes en servicios de hosting, dominios, correos
                        electrónicos y más.
                    </li>
                    <li class="about-main__list-item">
                        Navega por nuestras categorías o busca directamente descuentos de tus marcas favoritas
                        como GoDaddy, Factoria Digital, Banahosting o Siteground.
                    </li>
                    <li class="about-main__list-item">
                        Aprovecha los códigos promocionales y enlaces directos para activar las mejores
                        ofertas disponibles.
                    </li>
                </ul>

                <p class="about-main__text">
                    Con una interfaz clara y actualizaciones constantes, <b>Cupohosting</b> pone en tus manos
                    todas las opciones para ahorrar tiempo y dinero en tu nicho o negocio digital.
                </p>

                <h2 class="about-main__subtitle">¿Cómo ganamos dinero?</h2>

                <p class="about-main__text">
                    Nuestra plataforma es completamente gratuita para los usuarios. <b>Cupohosting</b> se
                    financia mediante enlaces de afiliados, lo que significa que algunas ofertas contienen un
                    enlace que genera una comisión si completas la compra. Este modelo no afecta el precio que
                    pagas, asegurando que siempre obtengas el máximo ahorro posible.
                </p>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
const Route = useRoute()

const {
    public: { siteOrigin },
} = useRuntimeConfig()

const description =
    'Aprende más sobre Cupohosting y quién está detrás. Conoce quiénes somos, nuestras actividades y por qué elegirnos.'
const title = 'Quiénes somos | Cupohosting'

useSeoMeta({
    title: title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})
</script>

<style lang="postcss">
.about {
    &-main {
        @apply m-0 mx-auto space-x-2 lg:container md:mb-10 md:mt-4 lg:max-w-screen-xl;
        &__body {
            @apply mx-auto my-4 max-w-screen-xl overflow-hidden bg-white lg:container md:rounded-3xl lg:w-3/4;
        }
        &__content {
            @apply px-6 py-4;
        }

        &__title {
            @apply mb-6 mt-4 text-center text-[28px] font-medium lg:text-[32px];
        }

        &__subtitle {
            @apply mb-4 text-2xl lg:text-[28px];
        }

        &__img {
            @apply mb-6 block w-full object-contain md:mb-8 md:h-[500px];
        }

        &__text {
            @apply mb-6 text-base;
        }

        &__link {
            @apply font-semibold underline;
        }

        &__team {
            @apply mt-8 space-y-8;

            &-line {
                @apply w-full bg-gray-200;
            }

            .team-card {
                @apply flex flex-col gap-2 last:mb-4;

                &__info {
                    @apply flex gap-4;

                    .info-img {
                        @apply h-32 w-32 rounded-3xl object-contain;
                    }

                    .info-content {
                        @apply flex flex-col justify-center;

                        &__name {
                            @apply text-lg font-semibold;
                        }

                        &__job {
                            @apply text-base;
                        }

                        &__nav {
                            @apply flex items-center space-x-3;

                            a {
                                @apply underline;
                            }
                        }
                    }
                }

                &__text {
                    @apply col-span-5 row-start-4 text-base;
                }
            }
        }
    }
}
</style>
